<template>
  <div class="my-plan-content">
    <p class="mt-5 text-center font-weight-bold" v-if="planListData.length === 0">尚未建立登山計畫</p>
    <MyPlanGroupCard :card-data="item" :card-type="planType" v-for="(item, index) in newListData" :key="index"></MyPlanGroupCard>
  </div>
</template>

<script>
import MyPlanGroupCard from "@/views/plan/MyPlanGroupCard.vue";

export default {
  name: 'MyPlanJoinedGroup',
  data() {
    return {
      
    };
  },
  props: {
    planType: {
      required: true,
    },
    planListData: {
      type: Array,
      required: true,
      default() {
        return []
      },
    },
  },
  components: {
    MyPlanGroupCard,
  },
  computed: {
    newListData() {
      let newData = []
      if(this.planListData.length) {
        this.planListData.map(function(item) {
          let newObj = {}
          newObj.name = item.group.name;
          newObj.picUrl = item.group.journeyDetails[0].picUrl;
          newObj._id = item.group._id;
          newObj.startDate = item.group.journeyDetails[0].startDate;
          newObj.endDate = item.group.journeyDetails[0].endDate;
          newObj.master = item.group.master;
          newObj.members = item.members;
          newObj.durationDays = item.group.journeyDetails[0].durationDays;
          newData.push(newObj);
        });
      }
      return newData
    },
	},
  watch: {
    
  },
  mounted() {
    
	},
  methods: {
    deleteBtnClicked: function(e) {
      this.$emit('delete-btn-clicked', e[0]);
    },
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/basic";
.my-plan-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
</style>
<style lang="scss">
@import "src/assets/scss/basic";
.my-plan-content {
}
</style>
